// import google fonts <- import your google fonts here
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700,900);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,400i,700,900);

// import variables <- Override bootstrap core variables and define your own variables
@import "helpers/custom-variables";

// import mixins <- define your mixins
@import "helpers/mixins";

/* bootstrap core styles <- This is path to core bootstrap files*/
@import "../node_modules/bootstrap/scss/bootstrap";